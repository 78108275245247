import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
// import { AboutUsComponent } from './pages/about-us/about-us.component';
// import { ContactUsComponent } from './pages/contact-us/contact-us.component';
// import { GalleryComponent } from './pages/gallery/gallery.component';
// import { BlogsComponent } from './pages/blogs/blogs.component';
// import { BlogDetailComponent } from './pages/blog-detail/blog-detail.component';
// import { TermsComponent } from './pages/terms/terms.component';
import { Blog1Component } from './pages/blog1/blog1.component';
import { Blog2Component } from './pages/blog2/blog2.component';
import { PageNotFoundComponent } from "./common/page-not-found/page-not-found.component";

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'blog/career-options-after-msc-microbiology', component: Blog1Component },
  { path: 'blog/overview-of-msc-biotechnology', component: Blog2Component },
  { path: '**', component: PageNotFoundComponent }
  
  // { path: 'contact-us', component: ContactUsComponent },
  // { path: 'gallery', component: GalleryComponent },
  // { path: 'blogs', component: BlogsComponent },
  // { path: 'blog-detail/:id', component: BlogDetailComponent },
  // { path: 'blog-detail', component: BlogDetailComponent },
  // { path: 'terms', component: TermsComponent },
  // otherwise redirect to page not found
];


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(
      routes,
      // { enableTracing: true } // <-- debugging purposes only
    )
  ],
  exports: [
    RouterModule
  ],
})
export class AppRoutingModule { }
