import { BrowserModule } from '@angular/platform-browser';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';

import { AngularFontAwesomeModule } from 'angular-font-awesome';

import { AppComponent } from './app.component';
// import { HeaderComponent } from './common/header/header.component';
// import { FooterComponent } from './common/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
// import { AboutUsComponent } from './pages/about-us/about-us.component';
// import { ContactUsComponent } from './pages/contact-us/contact-us.component';
// import { SliderComponent } from './common/slider/slider.component';
// import { BannerComponent } from './common/banner/banner.component';
// import { GalleryComponent } from './pages/gallery/gallery.component';
// import { BlogsComponent } from './pages/blogs/blogs.component';
// import { BlogDetailComponent } from './pages/blog-detail/blog-detail.component';
// import { TermsComponent } from './pages/terms/terms.component';
import { AppRoutingModule } from './app-routing.module';
import { Blog1Component } from './pages/blog1/blog1.component';
import { Blog2Component } from './pages/blog2/blog2.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  //return new TranslateHttpLoader(http);
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    // HeaderComponent,
    // FooterComponent,
    HomeComponent,
    PageNotFoundComponent,
    Blog1Component,
    Blog2Component,
    // AboutUsComponent,
    // ContactUsComponent,
    // SliderComponent,
    // BannerComponent,
    // GalleryComponent,
    // BlogsComponent,
    // BlogDetailComponent,
    // TermsComponent
  ],
  imports: [
    BrowserModule,
    DatePickerModule,
    HttpClientModule,
    AngularFontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
   }),
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
