import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public url;
  constructor() {
      // this.url = 'http://localhost/codeigniter/admin/';
      // this.url = 'http://localhost/codeigniter/ppl-admin/admin/';
      //this.url = 'http://localhost/codeigniter/ppl-admin/';
      //this.url = 'http://mscadmission.nirmauni.ac.in/admin/';
      //  this.url = 'http://mscadmission.nirmauni.ac.in/admin/';
      this.url = 'https://isnu.muskowl.com/admin/';

      // this.url =   window.location.origin +'/admin/';

  }
}
