import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { FormService } from '../../services/form/form.service';
import { InquiryService } from '../../services/inquiry/inquiry.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language/language.service';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-blog2',
  templateUrl: './blog2.component.html',
  styleUrls: ['./blog2.component.css']
})

export class Blog2Component implements OnInit {
  public coursesForm: FormGroup;
  public contactForm: FormGroup;

 // public countries: any[] = [];
  public genders: any[] = [];
  public zones: any[] = [];

  public courses: any[] = [];
  public subcourses: any[] = [];
  public subcourseslist: any[] = [];
  public subcoursedetail: any[] = [];

  public responseData;

  public status;
  public msgSuccess;
  public msgInfo;
  public msgWarning;
  public message;
  public cDescription: any = [];

  public contact_us_header;
  public inquery_txt_caps;
  public submit_btn_txt_caps;
  public something_wrong_txt;
  public message_sent_txt;
  public result;
  public draw;
  public totalRecords;
  public filterdRecords;

  public name: string;
  public contact: string;

 // public country: string;
  public course: string;
  public gender: string;
  public ug_percentage : string;
  public zone: string;
  public city: string;
  public subcourse: string;
  public referred: string;
  public others: string;
  public email: string;
  public agree: string;

  public dreamCourses: string;

  errors: any;

  checkModel = false;

  public formErrors = {
    name: '',
    contact: '',
    gender: '',
    ug_percentage :'',
    //country: '',
    zone: '',
    city: '',
    course: '',
    subcourse: '',
    referred: '',
    others: '',
    email: '',
    agree: '',

    dreamCourses: '',
  };
  subcoursedetails: any ='about';


  constructor(
    private formBuilder: FormBuilder,
    private formService: FormService,
    private inquiryService: InquiryService,
    private router: Router,
    private translate: TranslateService,
    public languageService: LanguageService, 
  ) { 
    this.name = '';
    this.email = '';
    this.contact = '';
    this.gender = '';
    this.ug_percentage = '';
    //this.country = '';
    this.zone = '',
    this.city = '';
    this.course = '';
    this.subcourse = '';
    this.referred = '';
    this.others = '';

    this.agree = '';

    this.dreamCourses;

    this.createCourseForm();
    this.createForm();
    this.setText();

    this.getGender();
    this.getZones();
    //this.getCountries();
    this.getCourses();
    this.getSubCoursesList();
  }

  ngOnInit() {

    var btn = $('#tButton');

  $(window).scroll(function() {
    if ($(window).scrollTop() > 400) {
      btn.addClass('show');
    } else {
      btn.removeClass('show');
    }
  });

  btn.on('click', function(e) {
    e.preventDefault();
    $('html, body').animate({scrollTop:0}, '400');
  });

      // $('.nav-link').on('click', function () {
      //   $('.navbar-collapse').collapse('hide');
      // });
      // $(window).scroll(function () {
      //   var sticky = $('.make-sticky'),
      //     scroll = $(window).scrollTop();
  
      //   if (scroll >= 100) sticky.addClass('fixed');
      //   else sticky.removeClass('fixed');
      // });

      jQuery(document).ready(function(){
        

        jQuery("#other_source").hide();
        jQuery("#about-course").hide();

        jQuery('#custom-select-ref').on('change', function() {
      
          if ( this.value == 'Others')
          {
            jQuery("#other_source").show();
          } 
          else {
            jQuery("#other_source").hide();
          }
          
        });

        jQuery('#custom-dream-course').on('change', function() {
            jQuery("#about-course").show();
            jQuery("#about-nirma").hide();
        });

     });
  
    this.coursesForm.controls['dreamCourses'].setValue(['0'], {onlySelf: true});
    this.contactForm.controls['gender'].setValue(['0'], {onlySelf: true});
    // this.contactForm.controls['country'].setValue(['0'], {onlySelf: true});
    this.contactForm.controls['zone'].setValue(['0'], {onlySelf: true});
    this.contactForm.controls['course'].setValue(['0'], {onlySelf: true});
    this.contactForm.controls['subcourse'].setValue(['0'], {onlySelf: true});
    //this.contactForm.controls['referred'].setValue(['0'], {onlySelf: true});
  }

  setText() {
    this.translate.setDefaultLang(this.languageService.getLanguage());
    this.translate.use(this.languageService.getLanguage());

    this.translate.get('contact_us_caps').subscribe((text: string) => {
      this.contact_us_header = text;
    });
    this.translate.get('inquery_caps').subscribe((text: string) => {
      this.inquery_txt_caps = text;
    });
    this.translate.get('submit_btn_caps').subscribe((text: string) => {
      this.submit_btn_txt_caps = text;
    });
    this.translate.get('something_wrong').subscribe((text: string) => {
      this.something_wrong_txt = text;
    });
    this.translate.get('message_sent').subscribe((text: string) => {
      this.message_sent_txt = text;
    });
  }

  public createCourseForm() {
    this.coursesForm = this.formBuilder.group({
      dreamCourses: [this.dreamCourses, Validators.compose([
        Validators.required
      ])],
    });
    this.coursesForm.valueChanges.subscribe((data) => {
      this.formErrors = this.formService.validateForm(this.coursesForm, this.formErrors, true)
    });
  }

  public createForm() {
    this.contactForm = this.formBuilder.group({
      name: [this.name, Validators.compose([
        Validators.required
      ])],
      email: [this.email,
        Validators.compose([
          Validators.required,
      ])],
      contact: [this.contact, Validators.compose([
        Validators.required
      ])],
      gender: [this.gender, Validators.compose([
        Validators.required
       ])],
       ug_percentage: [this.ug_percentage, Validators.compose([
        Validators.required
       ])],
      zone:  [this.zone, Validators.compose([
        Validators.required
      ])],
      city: [this.city, Validators.compose([
        Validators.required
      ])],
      course: [this.course, Validators.compose([
        Validators.required
      ])],
      subcourse: [this.subcourse],
     // referred: [this.referred],
      //others: [this.others],
      agree: [this.agree, Validators.compose([
        Validators.required
      ])],
    });

    this.contactForm.valueChanges.subscribe((data) => {
      this.formErrors = this.formService.validateForm(this.contactForm, this.formErrors, true)
    });
  }

  submitForm() {
    this.formService.markFormGroupTouched(this.contactForm);
    if (this.contactForm.valid) {
      this.inquiryService.sendDetails(this.contactForm.value).subscribe(
        response => {
          this.responseData = response;
 
          this.status = this.responseData.status;
          this.message = this.responseData.message;
 
          if (!this.status) {
            this.msgSuccess = '';
            this.msgWarning = 'Something went wrong please try again later.';
            if (this.responseData.result) {
               this.responseData.result.forEach(element => {
                if (element.id == 'name') {
                  this.formErrors.name = element.text;
                }
                if (element.id == 'email') {
                     this.formErrors.email = element.text;
                 }
                if (element.id == 'contact') {
                  this.formErrors.contact = element.text;
                }
                if (element.id == 'gender') {
                  this.formErrors.gender = element.text;
                }
                if (element.id == 'ug_percentage') {
                  this.formErrors.ug_percentage = element.text;
                }
                if (element.id == 'zone') {
                  this.formErrors.zone = element.text;
                }
                if (element.id == 'city') {
                  this.formErrors.city = element.text;
                }
                if (element.id == 'course') {
                  this.formErrors.course = element.text;
                }
                if (element.id == 'subcourse') {
                   this.formErrors.subcourse = element.text;
                }
                // if (element.id == 'referred') {
                //   this.formErrors.referred = element.text;
                // }
                // if (element.id == 'others') {
                //   this.formErrors.others = element.text
                // }
              });
            }
          } else {
            this.msgWarning = '';
            this.msgSuccess = 'Thank you for apply.';
            this.contactForm.reset();
          }

        },
        err => {
          console.error(err);
        }
      );
 
    } else {
      this.formErrors = this.formService.validateForm(this.contactForm, this.formErrors, false);  
      this.formErrors.agree = 'You must agree to the terms and conditions before apply.';  
    }
  }

  submitCoursesForm() {
    
    // this.contactForm.value.image = event.srcElement[0].files[0].name;
 
     //console.log(this.coursesForm.value);
 
     this.formService.markFormGroupTouched(this.coursesForm);
     if (this.coursesForm.valid) {
       // this.contactForm.value.image = event.srcElement[0].files[0].name;
       this.inquiryService.sendDetails(this.coursesForm.value).subscribe(
         response => {
           this.responseData = response;
  
           this.status = this.responseData.status;
           this.message = this.responseData.message;
  
           if (!this.status) {
             this.msgSuccess = '';
             this.msgInfo = '';
             this.msgWarning = 'Something went wrong please try again later';
             if (this.responseData.result) {
                this.responseData.result.forEach(element => {
                 if (element.id == 'dreamCourses') {
                   this.formErrors.dreamCourses = element.text;
                 }
               });
             }
           } else {
             this.msgWarning = '';
             this.msgSuccess = 'Thank you for registration.';
       // this.msgInfo = '<p>"Please come to Wonder Cricket Academy, Near Shikarbadi Hotel, Udaipur within two days for your trial."</p>';
            // this.msgInfo = "<p>Please come to Wonder Cricket Academy, Near Shikarbadi Hotel, Udaipur within two days for your trial.</p>";
             this.coursesForm.reset();
           }
 
         },
         err => {
           console.error(err);
         }
       );
  
     } else {
       this.formErrors = this.formService.validateForm(this.coursesForm, this.formErrors, false);  
     }
   }

  getZones() {
    this.zones = [];
    this.inquiryService.getZoneslist().subscribe(
      response => {
        this.result = response;
        this.totalRecords = this.result.recordsTotal;
        this.filterdRecords = this.result.recordsFiltered;
        for (let index = 0; index < this.result.data.length; index++) {
          this.zones.push({
            id: this.result.data[index].id,
            country: this.result.data[index].country,
            name: this.result.data[index].name,
            code: this.result.data[index].code,
            status: this.result.data[index].status,
            created_date: this.result.data[index].created_date,
            modified_date: this.result.data[index].modified_date
          });
        }
      },
      err => {
        console.error(err)
      },
    );
  }

  getCourses() {
    this.courses = [];

    this.inquiryService.getCourseslist().subscribe(
      response => {
        this.result = response;
        this.draw = response.draw;
        this.totalRecords = response.recordsTotal;
        this.filterdRecords = response.recordsFiltered;
        for (let index = 0; index < this.result.data.length; index++) {
          this.courses.push({
            id: this.result.data[index].id,
            name: this.result.data[index].name,
            description: this.result.data[index].description,
            status: this.result.data[index].status,
            created_date: this.result.data[index].created_date,
            modified_date: this.result.data[index].modified_date
          });
        }
      },
      err => {
        console.error(err)
      },
    );
  }

  getSubcourses(category_id) {
    this.subcourses = [];

    this.inquiryService.getSubcourseslist(category_id).subscribe(
      response => {
        this.result = response;
        //this.draw = response.draw;
        this.totalRecords = response.recordsTotal;
        this.filterdRecords = response.recordsFiltered;
        for (let index = 0; index < this.result.data.length; index++) {
          this.subcourses.push({
            id: this.result.data[index].id,
            course_id: this.result.data[index].course_id,
            name: this.result.data[index].name,
            about: this.result.data[index].about,
            contact: this.result.data[index].contact,
            duration: this.result.data[index].duration,
            eligibility: this.result.data[index].eligibility,
            scholarship: this.result.data[index].scholarship,
            status: this.result.data[index].status,
            created_date: this.result.data[index].created_date,
            modified_date: this.result.data[index].modified_date
          });
        }
      },
      err => {
        console.error(err)
      },
    );
  }

  getSubCoursesList() {
    this.subcourseslist = [];

    this.inquiryService.getAllSubCourses().subscribe(
      response => {
        this.result = response;
        //this.draw = response.draw;
        this.totalRecords = response.recordsTotal;
        this.filterdRecords = response.recordsFiltered;
        for (let index = 0; index < this.result.data.length; index++) {
          this.subcourseslist.push({
            id: this.result.data[index].id,
            course_id: this.result.data[index].course_id,
            name: this.result.data[index].name,
            about: this.result.data[index].about,
            contact: this.result.data[index].contact,
            duration: this.result.data[index].duration,
            eligibility: this.result.data[index].eligibility,
            scholarship: this.result.data[index].scholarship,
            status: this.result.data[index].status,
            created_date: this.result.data[index].created_date,
            modified_date: this.result.data[index].modified_date
          });
        }
      },
      err => {
        console.error(err)
      },
    );
  }

  getGender() {
    this.genders = [];

    this.inquiryService.getGenderlist().subscribe(
      response => {
        this.result = response;
       // this.draw = response.draw;
        this.totalRecords = response.recordsTotal;
        this.filterdRecords = response.recordsFiltered;
        for (let index = 0; index < this.result.data.length; index++) {
          this.genders.push({
            id: this.result.data[index].id,
            title: this.result.data[index].title,
            status: this.result.data[index].status,
            created_date: this.result.data[index].created_date,
            modified_date: this.result.data[index].modified_date
          });
        }
      },
      err => {
        console.error(err);
      },
    );
  }

  isTermProvided(event: any)
  {
    this.checkModel = event.target.checked;
    // console.log("check:", this.checkModel);
    // console.log(event);
  }

  getDescriptiopn(sub_course_id) {
    this.subcourses = [];

    this.inquiryService.getSubcourseDetail(sub_course_id).subscribe(
      response => {
        this.result = response;
        this.subcoursedetail = response.result;
      },
      err => {
        console.error(err)
      },
    );
  }

  onChange(value){
    this.subcoursedetails = value;
  }

  downloadPdf(myObj: any) {
    this.inquiryService.downloadDoc(myObj.id).subscribe(result => {
        var url = window.URL.createObjectURL(result);
        window.open(url);
        console.log("download result ", result);
    });
}

downloadMyFile(){
  const link = document.createElement('a');
  link.setAttribute('target', '_blank');
  link.setAttribute('href', 'assets/image/ISNU-Prospectus-2021.pdf');
  link.setAttribute('download', `assets/image/ISNU-Prospectus-2021.pdf`);
  document.body.appendChild(link);
  link.click();
  link.remove();
}
}