import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language/language.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  public fourZeroFour;
  public pageNotFoundHdr;
  public pageNotFoundTxt;
  public pageNotFoundRsnHdr;
  public pageNotFoundRsn1;
  public pageNotFoundRsn2;
  public pageNotFoundRsn3;
  public pageNotFoundSugg;
  public pageNotFoundHome;

  constructor(
    public translate: TranslateService,
    public languageService: LanguageService,
  ) { 
    this.setText();
  }

  ngOnInit(): void {
  }

  setText() {
    this.translate.setDefaultLang(this.languageService.getLanguage());
    this.translate.use(this.languageService.getLanguage());
    this.translate.get('for_zero_four').subscribe((text: string) => {
      this.fourZeroFour = text;
    });
    this.translate.get('page_not_found_hdr').subscribe((text: string) => {
      this.pageNotFoundHdr = text;
    });
    this.translate.get('page_not_found_txt').subscribe((text: string) => {
      this.pageNotFoundTxt = text;
    });
    this.translate.get('page_not_found_reason_hdr').subscribe((text: string) => {
      this.pageNotFoundRsnHdr = text;
    });
    this.translate.get('page_not_found_reason_1').subscribe((text: string) => {
      this.pageNotFoundRsn1 = text;
    });
    this.translate.get('page_not_found_resaon_2').subscribe((text: string) => {
      this.pageNotFoundRsn2 = text;
    });
    this.translate.get('page_not_found_resaon_3').subscribe((text: string) => {
      this.pageNotFoundRsn3 = text;
    });
    this.translate.get('page_not_found_suggestion').subscribe((text: string) => {
      this.pageNotFoundSugg = text;
    });
    this.translate.get('homepage').subscribe((text: string) => {
      this.pageNotFoundHome = text;
    });
  }

}
