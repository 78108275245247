import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config/config.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InquiryService {

  public headers = new HttpHeaders();
  public formData: FormData = new FormData();
  public responseData: any;
  private URL;
 // public image;
  //public name;
  //public contact;
 
 // public dob;
  public qualification;
  public email;
  private url;
  public name ;
  public mobile;
  public gender;
  public country;
  public zones;
  public district;
  public course;
  public subcourse;
  public referred;
  // public jersey;
  // public jerseyNo1;
  // public jerseyNo2;
  // public jerseyNo3;
  // public payment;

  constructor(
    public http: HttpClient,
    private configService: ConfigService
  ) { 
    this.headers.set('Access-Control-Allow-Origin ', '*');
    this.headers.set('Content-Type', 'application/json; charset=utf-8');
  }

  public getBowlersTypes(): any {
    this.formData = new FormData();
    this.URL = this.configService.url + 'cricket_module/api/Cricket_bolling_types_api';
    return this.http.post(this.URL,
      this.formData,
      {
        headers: this.headers,
      }
    );
  }

  public getBowlingsTypes(): any {
    this.formData = new FormData();
    this.URL = this.configService.url + 'cricket_module/api/Cricket_bowlling_types_api';
    return this.http.post(this.URL,
      this.formData,
      {
        headers: this.headers,
      }
    );
  }

  public getBattingTypes(): any {
    this.formData = new FormData();
    this.URL = this.configService.url + 'cricket_module/api/Cricket_batting_types_api';
    return this.http.post(this.URL,
      this.formData,
      {
        headers: this.headers,
      }
    );
  }

  public getRoalTypes(): any {
    this.formData = new FormData();
    this.URL = this.configService.url + 'cricket_module/api/Cricket_roles_api';
    return this.http.post(this.URL,
      this.formData,
      {
        headers: this.headers,
      }
    );
  }

  public imageUpload(image: File) {
    this.formData = new FormData();
    this.URL = `${this.configService.url}cricket_module/api/cricket_players_ppl_api/image_upload`;
    this.formData.append('userfile', image);
    return this.http.post<any>(this.URL, this.formData).pipe(
      // retry(1), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }

  public sendDetails(data: any) {
    this.formData = new FormData();
    this.URL = this.configService.url + 'student_module/api/student_api/save';

    // this.formData.append('image', data.image);
    this.formData.append('name', data.name);
    this.formData.append('email', data.email);
    this.formData.append('contact', data.contact);
    this.formData.append('gender_id', data.gender);
    this.formData.append('ug_percentage', data.ug_percentage);
   // this.formData.append('dob', data.dob);
   //this.formData.append('country', data.country);
    this.formData.append('zone_id', data.zone);
    this.formData.append('city', data.city);
    this.formData.append('course_id', data.course);
    this.formData.append('subcourse_id', data.subcourse);
    //this.formData.append('district', data.district);
    this.formData.append('refer', data.referred);
    this.formData.append('referother', data.others);
    return this.http.post(this.URL,
        this.formData,
        {
            headers: this.headers,
        }
    );
 }

 private handleError(error: HttpErrorResponse) {
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', error.error.message);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
    console.error(
      `Backend returned code ${error.status}, ` + `body was: ${error.error}`
    );
  }
  // return an observable with a common-facing error message
  return throwError('Something bad happened; please try again later.');
}

public getCountrieslist(): any {
  this.url = this.configService.url + 'address_module/api/countries_api';
  return this.http.post(this.url, this.formData,
  {
      headers: this.headers,
  });
}

//  public getZoneslist(country_id) {
//   this.formData = new FormData();
//   this.url = this.configService.url + 'address_module/api/Zones_api';
//   this.formData.append('country_id', country_id);
//   return this.http.post(this.url, this.formData,
//   {
//       headers: this.headers,
//   });
// }

public getZoneslist() {
  this.formData = new FormData();
  this.url = this.configService.url + 'address_module/api/zones_api';
//  this.formData.append('country_id', country_id);
  return this.http.post(this.url, this.formData,
  {
      headers: this.headers,
  });
}

public getGenderlist(): any {
  this.URL = this.configService.url + 'user_module/api/genders_api';
  return this.http.post(this.URL, this.formData,
  {
      headers: this.headers,
  });
}

public getCourseslist(): any {
  // this.URL = this.configService.url + 'product_module/api/categories_api';
  this.URL = this.configService.url + 'address_module/api/courses_api';
  return this.http.post(this.URL, this.formData,
  {
      headers: this.headers,
  });
}

public getSubcourseslist(category_id): any {
  this.URL = this.configService.url + 'address_module/api/sub_courses_api';
  this.formData.append('course_id', category_id);
  return this.http.post(this.URL, this.formData,
  {
      headers: this.headers,
  });
}

public getAllSubCourses(): any {
  // this.URL = this.configService.url + 'product_module/api/categories_api';
  this.URL = this.configService.url + 'address_module/api/sub_courses_api';
  return this.http.post(this.URL, this.formData,
  {
      headers: this.headers,
  });
}

public getSubcourseDetail(sub_course_id): any {
  this.URL = this.configService.url + 'address_module/api/sub_courses_api/detail/'+ sub_course_id;
 // this.formData.append('id', sub_course_id);
  return this.http.post(this.URL, this.formData,
  {
      headers: this.headers,
  });
}

public downloadDoc(Id: string): Observable<any> {
  this.URL = this.configService.url + 'address_module/api/sub_courses_api/downloadpdf/'+ Id;
  //let url = this.apiUrl + "api/myApi/download/" + Id;
  return this.http.get(this.URL, { responseType: "blob" });
}
}
