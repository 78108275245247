import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  public headers = new HttpHeaders();
  public formData: FormData = new FormData();
  public responseData: any;
  private url;

  public language;
  public language_id;

  constructor(
    public http: HttpClient,
    private configService: ConfigService,
  ) {
    this.language = 'english';
    this.language_id = 1;

    this.language = this.getLanguage();
    this.language_id = this.getLanguageId();
  }

  public getLanguages() {
    this.formData = new FormData();
    this.url = this.configService.url + 'settings/api/languages_api';
    return this.http.post(this.url,
      this.formData,
      {
        headers: this.headers,
      }
    );
  }

  public setLanguage(data) {
    try {
      window.localStorage.setItem('language_id', data.id);
      window.localStorage.setItem('language', data.code);
    } catch (error) {
    }
  }

  public getLanguage() {
    try {
      if (window.localStorage.getItem('language')) {
        this.language = window.localStorage.getItem('language');
      }
      return this.language;
    } catch (error) {
      return this.language;
    }
  }



  public getLanguageId() {
    try {
      if (window.localStorage.getItem('language_id')) {
        this.language_id = window.localStorage.getItem('language_id');
      }
      return this.language_id;
    } catch (error) {
      return this.language_id;
    }
  }
}
